.tab{
  position: relative;
  padding: 6px 26px 6px 16px;
  margin-right: 5px;
  background-color: #fff;
  .tab-link{
    color: rgba(0, 0, 0, 0.85)
  }
}

.tab-close{
  position: absolute;
  right: 6px;
  top: 10px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);
  transition: transform .2s;
  margin-left: 5px;
  &:hover{
    transform: scale(1.1);
  }
}

.tab.active{
  .tab-close{
    color: #1DA57A;
  }
  
  .tab-link{
    color: #1DA57A;
  }
}
@primary-color: #1DA57A;