.tabs {
  position: relative;
  display: flex;
  margin: 5px;
}
.context-menu {
  position: absolute;
  z-index: 100;
  width: 100px;
}

@primary-color: #1DA57A;