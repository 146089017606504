.layout-title{
  color: #fff;
  text-align: center;
  line-height: 64px;
  height: 64px;
  overflow: hidden;
  margin: 0;
  font-size: 18px;
}
.layout-logo{
  width: 30px;
  height: 30px;
}
@primary-color: #1DA57A;